import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const AppFooter = () => {
    const currentYear = new Date().getFullYear();
    
    return (
        <Footer style={{ textAlign: 'left', backgroundColor: '#f0f2f5' }}>
            Baja Travel Transportation © {currentYear}  Version BETA 0.5
            <br />
            Los Cabos, Baja California Sur, México
        </Footer>
    );
};

export default AppFooter;
