import React from 'react';
import { Layout} from 'antd';
import Header from '../utils/Header';
import Footer from '../utils/Footer';

const MainLayout = ({children}) => {

    return ( 
        <Layout headerBg="white" theme="light">
            <Header />
            <div className="bar-color-top"/>
            <Layout.Content style={{ padding: '0 48px' }}>
              {children}
            </Layout.Content>
            <Footer style={{ padding: '0 48px' }} />
        </Layout>
     );
}
export default MainLayout;