import React, { useState, useEffect, useContext } from 'react';
import moment, { min } from 'moment';
import { Card, Flex, Row, Col, Statistic, Typography, Spin, Alert, Select, Tabs } from 'antd';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts';
import { CarOutlined, UserOutlined, DollarOutlined } from '@ant-design/icons';
import useReservationsApi  from '../../controllers/reservations_controller';
import { QueryDatesContext } from '../../App';

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const Dashboard = () => {
  const { queryDates, setQueryDates } = useContext(QueryDatesContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limitData] = useState(undefined)
  const [error, setError] = useState(null);
  const [serviceFilter, setServiceFilter] = useState("all");

  const { getAllReservations } = useReservationsApi();

  // When queryDates changes, re-fetch the API data
  useEffect(() => {
    fetchData();
  }, [queryDates]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAllReservations({ ...queryDates, limit: limitData });
      setData(response.data.results);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  // Handle date range changes using Bootstrap DateRangePicker
  const handleDateRangeChange = (event, picker) => {
    setQueryDates({
      ...queryDates,
      date_init: picker.startDate.format('YYYY-MM-DD'),
      date_end: picker.endDate.format('YYYY-MM-DD')
    });
  };

  // Filter data based on serviceFilter selection
  const filteredData =
    serviceFilter !== "all"
      ? data.filter(item => item.type_service === (serviceFilter === "round" ? "Round Trip" : "One Way"))
      : data;

  // Filtrar reservaciones que no sean Cancelled en el Payment Method
  const nonCancelledData = filteredData.filter(item => item.payment_method !== 'Cancelled');

  // Process and transform the raw data for the charts
  const processData = (rawData) => {
    if (!rawData || rawData.length === 0)
      return {
        destinations: [],
        arrivalTimes: [],
        additionalServices: [],
        airlines: [],
        typeServiceStats: [],
        serviceStats: [],
        dailyReservations: [],
        paymentMethods: []
      };

    // Popular destinations
    const destinationStats = rawData.reduce((acc, curr) => {
      const destination = curr.arrival_to;
      acc[destination] = (acc[destination] || 0) + 1;
      return acc;
    }, {});

    // Arrival times
    const arrivalTimeStats = rawData.reduce((acc, curr) => {
      if (curr.arrival_time) {
        const hour = curr.arrival_time.split(':')[0];
        acc[hour] = (acc[hour] || 0) + 1;
      }
      return acc;
    }, {});

    // Additional services
    const additionalServices = rawData.reduce((acc, curr) => {
      if (curr.car_seats > 0) acc.car_seats = (acc.car_seats || 0) + curr.car_seats;
      if (curr.baby_seats > 0) acc.baby_seats = (acc.baby_seats || 0) + curr.baby_seats;
      if (curr.booster_seats > 0) acc.booster_seats = (acc.booster_seats || 0) + curr.booster_seats;
      if (curr.grocery_stop_subtotal) acc.grocery_stops = (acc.grocery_stops || 0) + 1;
      if (curr.golf_clubs_bags_qty > 0) acc.golf_clubs = (acc.golf_clubs || 0) + curr.golf_clubs_bags_qty;
      if (curr.surfboards_qty > 0) acc.surfboards = (acc.surfboards || 0) + curr.surfboards_qty;
      return acc;
    }, {
      car_seats: 0,
      baby_seats: 0,
      booster_seats: 0,
      grocery_stops: 0,
      golf_clubs: 0,
      surfboards: 0
    });

    // Airlines
    const airlineStats = rawData.reduce((acc, curr) => {
      const airline = curr.arrival_airline;
      acc[airline] = (acc[airline] || 0) + 1;
      return acc;
    }, {});

    // Reservations by service type
    const typeServiceStats = rawData.reduce((acc, curr) => {
      const type = curr.type_service;
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});

    // Reservations by vehicle/service
    const serviceStats = rawData.reduce((acc, curr) => {
      const service = curr.service;
      acc[service] = (acc[service] || 0) + 1;
      return acc;
    }, {});

    // Daily reservation trends (using created_on)
    const dailyReservations = rawData.reduce((acc, curr) => {
      const date = curr.created_on ? curr.created_on.split('T')[0] : '';
      if (date) {
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    // Payment methods
    const paymentMethods = rawData.reduce((acc, curr) => {
      const method = curr.payment_method;
      acc[method] = (acc[method] || 0) + 1;
      return acc;
    }, {});

    return {
      destinations: Object.entries(destinationStats)
        .map(([name, value]) => ({ name, value }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 10),
      arrivalTimes: Object.entries(arrivalTimeStats)
        .map(([hour, count]) => ({ hour: `${hour}:00`, count }))
        .sort((a, b) => a.hour.localeCompare(b.hour)),
      additionalServices: Object.entries(additionalServices)
        .map(([name, value]) => ({ name, value })),
      airlines: Object.entries(airlineStats)
        .map(([name, value]) => ({ name, value }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 10),
      typeServiceStats: Object.entries(typeServiceStats)
        .map(([name, value]) => ({ name, value })),
      serviceStats: Object.entries(serviceStats)
        .map(([name, value]) => ({ name, value })),
      dailyReservations: Object.entries(dailyReservations)
        .map(([date, count]) => ({ date, count }))
        .sort((a, b) => a.date.localeCompare(b.date)),
      paymentMethods: Object.entries(paymentMethods)
        .map(([name, value]) => ({ name, value }))
    };
  };

  const processedData = processData(filteredData);
  const COLORS = ['#1890ff', '#52c41a', '#faad14', '#f5222d', '#722ed1'];

  // Format dates for rendering so that we don't render Moment objects directly
  const formattedDateInit = moment(queryDates.date_init, "YYYY-MM-DD").format('MM-DD-YYYY');
  const formattedDateEnd = moment(queryDates.date_end, "YYYY-MM-DD").format('MM-DD-YYYY');

  return (
    <div style={{ padding: '0 24px 24px 24px' }}>
      {loading ? (
        <Spin tip="Loading..." size="large" style={{marginTop:'10%', marginLeft:'50%'}} />
      ) : error ? (
        <Alert message="Error" description={error} type="error" showIcon />
      ) : (
        <>
          <Flex justify="space-between" align="end" style={{width:'100%', marginBottom: '24px' }}>
            <div style={{ width: '100%' }}>
              <Title level={1}>Dashboard</Title>
              <Title level={5}>Fechas Consultadas:</Title>
              <Title level={4} style={{ marginBottom: 0, marginTop: 0 }}>
               <span>Inicio</span> {formattedDateInit} <span>- Fin:</span> {formattedDateEnd}
              </Title>
            </div>
            <Flex justify="space-between" align="center" gap="small" size="large">
              <div>
                <Typography.Paragraph style={{ marginRight: '8px' }}>Filtrar por rango de fechas:</Typography.Paragraph>
                <DateRangePicker
                  initialSettings={{
                    startDate: queryDates.date_init,
                    endDate: queryDates.date_end,
                    locale: { format: 'YYYY-MM-DD' }
                  }}
                  onApply={handleDateRangeChange}
                  >
                  <input
                    type="text"
                    className="input-date-range"
                    style={{ width: 300 }}
                    readOnly
                    />
                </DateRangePicker>
              </div>
              <div>
              <Typography.Paragraph style={{ marginRight: '8px' }}>Filtrar por servicio:</Typography.Paragraph>
                <Select
                  id="select_services"
                  defaultValue="all"
                  onChange={value => setServiceFilter(value)}
                  style={{minWidth: '150px'}}
                >
                  <Option value="all">Ambos servicios</Option>
                  <Option value="round">Round Trip</Option>
                
                  <Option value="oneway">One Way</Option>
                </Select>
              </div>
            </Flex>
          </Flex>                   
          <Tabs defaultActiveKey="1" style={{ marginTop: '24px', borderTop:'1px solid darkgray' }}>
            <TabPane tab="General" key="1">
              {/* KPIs */}
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={6}>
                  <Card>
                    <Statistic
                      title="Total Reservaciones"
                      value={filteredData.length}
                      prefix={<CarOutlined />}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Card>
                    <Statistic
                      title="Pasajeros Totales"
                      value={filteredData.reduce((sum, curr) => sum + curr.adults + curr.kids, 0)}
                      prefix={<UserOutlined />}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Card>
                    <Statistic
                      title="Ingreso Total (USD)"
                      value={nonCancelledData.reduce((sum, curr) => {
                        const fare = parseFloat(curr.total_fare);
                        return sum + (isNaN(fare) ? 0 : fare);
                      }, 0)}
                      prefix={<DollarOutlined />}
                      precision={2}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Card>
                    <Statistic
                      title="Promedio por Reserva (USD)"
                      value={
                        nonCancelledData.length > 0 
                          ? nonCancelledData.reduce((sum, curr) => {
                            const fare = parseFloat(curr.total_fare);
                            return sum + (isNaN(fare) ? 0 : fare);
                          }, 0) / nonCancelledData.length 
                          : 0
                      }
                      prefix={<DollarOutlined />}
                      precision={2}
                    />
                  </Card>
                </Col>
              </Row>

              {/* Charts */}
              <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
                <Col xs={24} md={12}>
                  <Card title="Reservaciones por Tipo de Servicio">
                    <PieChart width={400} height={300}>
                      <Pie
                        data={processedData.typeServiceStats}
                        cx={200}
                        cy={150}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label
                      >
                        {processedData.typeServiceStats.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </Card>
                </Col>

                <Col xs={24} md={12}>
                  <Card title="Reservaciones por Vehículo">
                    <BarChart width={450} height={300} data={processedData.serviceStats}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="value" fill="#1890ff" />
                    </BarChart>
                  </Card>
                </Col>

                <Col xs={24} md={12}>
                  <Card title="Tendencia de Reservaciones">
                    <LineChart width={400} height={300} data={processedData.dailyReservations}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="count" stroke="#52c41a" />
                    </LineChart>
                  </Card>
                </Col>

                <Col xs={24} md={12}>
                  <Card title="Métodos de Pago">
                    <PieChart width={400} height={300}>
                      <Pie
                        data={processedData.paymentMethods}
                        cx={200}
                        cy={150}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label
                      >
                        {processedData.paymentMethods.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="Destinos y Horarios" key="2">
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Card title="Top 10 Destinos">
                    <BarChart width={400} height={300} data={processedData.destinations}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" angle={45} textAnchor="start" height={100} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="value" fill="#1890ff" name="Reservaciones" />
                    </BarChart>
                  </Card>
                </Col>

                <Col xs={24} md={12}>
                  <Card title="Distribución de Horarios de Llegada">
                    <LineChart width={400} height={300} data={processedData.arrivalTimes}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="hour" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="count" stroke="#52c41a" name="Llegadas" />
                    </LineChart>
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="Servicios Adicionales" key="3">
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Card title="Servicios Adicionales Solicitados">
                    <BarChart width={400} height={300} data={processedData.additionalServices}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="value" fill="#722ed1" name="Cantidad" />
                    </BarChart>
                  </Card>
                </Col>

                <Col xs={24} md={12}>
                  <Card title="Top 10 Aerolíneas">
                    <PieChart width={400} height={300}>
                      <Pie
                        data={processedData.airlines}
                        cx={200}
                        cy={150}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label
                      >
                        {processedData.airlines.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
                <Col xs={24}>
                  <Card title="Desglose de Servicios Adicionales">
                    <Row gutter={[16, 16]}>
                      {processedData.additionalServices.map((service) => (
                        <Col xs={24} sm={12} md={8} key={service.name}>
                          <Card>
                            <Statistic
                              title={service.name.replace(/_/g, ' ').toUpperCase()}
                              value={service.value}
                              precision={0}
                            />
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default Dashboard;