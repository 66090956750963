import axios from 'axios';
import { configs } from '../configs/configs';
import { useAuth } from '../context/AuthContext';

const useReservationsApi = () => {
  const { idToken } = useAuth();

  const getAuthHeader = () => {
    console.log("Token en useReservationsApi:", idToken);
    return { Authorization: `Bearer ${idToken}` };
  };

  const getAllReservations = (params) => {
    return axios.get(`${configs.URL_API_BASE}/reservations`, {
      params,
      headers: getAuthHeader(),
    });
  };

  const getAllReservationsByDateRange = (dateRange) => {
    console.log("Token en useReservationsApi:", idToken);
    return axios.get(`${configs.URL_API_BASE}/reservations`, {
      params: dateRange,
      headers: getAuthHeader(),
    });
  };

  const updateReservation = (id, dataToUpdate) => {
    return axios.put(`${configs.URL_API_BASE}/reservations/${id}`, dataToUpdate, {
      headers: getAuthHeader(),
    });
  };

  const getAirlines = () => {
    return axios.get(`${configs.URL_API_BASE}/airlines`, {
      headers: getAuthHeader(),
    });
  };

  return { getAllReservations, getAllReservationsByDateRange, updateReservation, getAirlines };
};

export default useReservationsApi;