import React, { useState } from 'react';
import { Card, Form, Input, Button, Alert, Row, Col } from 'antd';
import { firebase } from '../../firebase/firebase-config';
import { useHistory } from 'react-router-dom';

const Index = () => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setError(null);
        setLoading(true);
        try {
            await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
            // Obtener el token del usuario autenticado
            const token = await firebase.auth().currentUser.getIdToken(true);
            if (token) {
                // Almacenar el token y redirigir
                localStorage.setItem('idToken', token);
                history.push('/reservations');
            } else {
                setError("No se pudo obtener el token.");
            }
        } catch (err) {
            setError(err.message);
            console.log(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
            <Col xs={22} sm={16} md={12} lg={8} xl={6}>
                <Card>
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/bajatravel-transportation-logo.png`}
                            alt="RCI"
                            style={{ width: 200 }}
                        />
                        <h3>Login</h3>
                    </div>
                    {error && (
                        <Alert
                            message={error}
                            type="error"
                            showIcon
                            style={{ marginBottom: '20px' }}
                        />
                    )}
                    <Form name="login-form" layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: 'Please enter your email' },
                                { type: 'email', message: 'No es un email válido' },
                            ]}
                        >
                            <Input placeholder="Enter your email" />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please enter your password' }]}
                        >
                            <Input.Password placeholder="Enter your password" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block loading={loading}>
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default Index;