import React from 'react';
import { Layout, Menu, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { firebase } from '../../firebase/firebase-config';

const { Header } = Layout;

const AppHeader = () => {
    const history = useHistory();

    const logOut = async () => {
        try {
            await firebase.auth().signOut();
            history.push('/');
            localStorage.removeItem('idToken');
        } catch (error) {
            console.error("Error al cerrar sesión:", error);
        }
    };

    // Definir estilos manualmente, o usar variables definidas en tu SCSS
    const headerStyle = {
        background: '#fff', // Puedes reemplazar este valor por el color deseado
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    return (
        <Header style={headerStyle}>
            <div className="logo">
                <img 
                    src={process.env.PUBLIC_URL + '/bajatravel-transportation-logo.png'} 
                    alt="Transroute" 
                    style={{ width: 100 }} 
                />
            </div>
            <Menu theme="light" mode="horizontal" selectable={false} style={{ flex: 1, justifyContent: 'flex-end' }}>
                <Menu.Item key="dashboard" onClick={() => history.push('/dashboard')}>
                    Dashboard
                </Menu.Item>
                <Menu.Item key="reservations" onClick={() => history.push('/reservations')}>
                    Reservations
                </Menu.Item>
                <Menu.Item key="logout">
                    <Button type="default" onClick={logOut} icon={<LogoutOutlined />}>
                        Logout
                    </Button>
                </Menu.Item>
            </Menu>
        </Header>
    );
};

export default AppHeader;