/* global __REACT_DEVTOOLS_GLOBAL_HOOK__ */

import React, { useState, createContext, useContext } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
// Layouts
import MainLayout from './components/layout/MainLayoutRoute';
import LoginLayout from './components/layout/LoginLayoutRoute';
// Components
import Login from './components/login/Index';
import Reservations from './components/reservations/Index';
import Dashboard from './components/dashboard/dashboard';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { configs } from './configs/configs';
// Crear contexto para las fechas consultadas (rango global)
export const QueryDatesContext = createContext(null);

if (configs.environment === 'production') {
  // Desactivar herramientas de desarrollo de React en producción
  if (typeof __REACT_DEVTOOLS_GLOBAL_HOOK__ !== 'undefined') {
    for (let [key, value] of Object.entries(__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      __REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === 'function' ? () => {} : null;
    }
  }
}
// Función para obtener los valores iniciales del rango de fechas
const getInitialQueryDates = () => {
    const queryEndDate = new Date();
    queryEndDate.setHours(23, 59, 59);

    const queryInitDate = new Date();
    queryInitDate.setMonth(queryInitDate.getMonth() - 1);
    queryInitDate.setHours(0, 0, 0);

    return {
        date_init: queryInitDate.toISOString().split('T')[0],
        date_end: queryEndDate.toISOString().split('T')[0]
    };
};

function App() {
    const [queryDates, setQueryDates] = useState(getInitialQueryDates);

    return (
        <AuthProvider>
            <QueryDatesContext.Provider value={{ queryDates, setQueryDates }}>
                <Router>
                    <Switch>
                        <PublicRoute exact path="/">
                            <LoginLayout>
                                <Login />
                            </LoginLayout>
                        </PublicRoute>
                        <PrivateRoute path="/dashboard">
                            <MainLayout>
                                <Dashboard />
                            </MainLayout>
                        </PrivateRoute>
                        <PrivateRoute path="/reservations">
                            <MainLayout>
                                <Reservations />
                            </MainLayout>
                        </PrivateRoute>
                        {/* Otras rutas */}
                    </Switch>
                </Router>
            </QueryDatesContext.Provider>
        </AuthProvider>
    );
}

const PublicRoute = ({ children, ...rest }) => {
    const { currentUser } = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !currentUser ? children : <Redirect to="/reservations" />
            }
        />
    );
};

const PrivateRoute = ({ children, ...rest }) => {
    const { currentUser } = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                currentUser ? children : <Redirect to="/" />
            }
        />
    );
};

export default App;