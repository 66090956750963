import React, { useState, useEffect } from 'react';
import { Button, Input, Row, Col, Spin } from 'antd';
import DataTable from 'react-data-table-component';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { InfoCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import styles from './ReservationsTable.module.scss';

const ReservationsTable = (props) => {
    const columns = [
        {
            name: "ID Reservation",
            selector: row => row.booking_id,
            sortable: true,
        },
        {
            name: "Created Date",
            selector: row => row.created_on ? new Date(row.created_on).toISOString().split('T')[0] : '',
            sortable: true,
        },
        {
            name: "Type Service",
            selector: row => row.type_service,
            sortable: true,
        },
        {
            name: "Client",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Arrival",
            selector: row => row.arrival_date ? moment(row.arrival_date).format('YYYY-MM-DD') : '',
            sortable: true,
        },
        {
            name: "Flight number",
            selector: row => row?.arrival_flight,
            sortable: true,
        },
        {
            name: "Departure",
            selector: row => row.departure_date ? moment(row.departure_date).format('YYYY-MM-DD') : '',
            sortable: true,
        },
        {
            name: "Transport",
            selector: row => row.service,
            sortable: true,
        },
        {
            name: "Arrival To",
            selector: row => row.arrival_to,
            sortable: true,
        },
        {
            name: "Actions",
            cell: row => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button type="default" onClick={() => props.handleSeeDetails(row)} icon={<InfoCircleOutlined />}>
                    </Button>
                    {/*
                    <Button onClick={() => props.handleEdit(row)} icon={<EditOutlined />}>
                        <EditOutlined />
                    </Button>
                    <Button type="default" onClick={() => props.resendEmail(row)} icon={<SendOutlined />}>
                        <SendOutlined />
                    </Button>
                    */}
                </div>
            )
        }
    ];

    const [records, setRecords] = useState(props.records);
    const [filteredRecords, setFilteredRecords] = useState(props.records);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setRecords(props.records);
        setFilteredRecords(props.records);
    }, [props.records]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearch(value);
        const filtered = records.filter(record =>
            Object.values(record).some(val =>
                String(val).toLowerCase().includes(value)
            )
        );
        setFilteredRecords(filtered);
    };

    const exportExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filteredRecords);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob(
            [excelBuffer],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;' }
        );
        FileSaver.saveAs(blob, 'reservations.xlsx');
    };

    // Implementación de onChangeRowsPerPage sin alterar las fechas actuales
    const handleRowsPerPageChange = (newPerPage, page) => {
        // Si se pasa una función desde el padre se invoca, o bien se podría actualizar el estado local o simplemente emitir un log.
        if (props.handleRowsPerPageChange) {
            props.handleRowsPerPageChange(newPerPage, page);
        }
        // Por ejemplo, podrías agregar un console.log para verificar el cambio:
        console.log('Rows per page changed to:', newPerPage, 'en page:', page);
    };

    return (
        <div className={styles.tableResponsive}>
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                <Col>
                    <h2>Table Reservations</h2>
                </Col>
                <Col>
                    <Button type="primary" onClick={exportExcel}>
                        <FileExcelOutlined /> Export Excel
                    </Button>
                </Col>
            </Row>
            <div className={styles.input}>
                <Input
                    type="text"
                    placeholder="Search..."
                    value={search}
                    onChange={handleSearch}
                    style={{ marginBottom: '16px' }}
                />
            </div>
            <DataTable
                columns={columns}
                data={filteredRecords}
                progressPending={props.loading}
                progressComponent={ <Spin tip="Loading..." size="large" style={{marginTop:'100px', minHeight:'200px'}}></Spin>}
                pagination
                paginationServer
                paginationTotalRows={props.totalRows}
                paginationPerPage={props.perPage}
                paginationDefaultPage={props.currentPage}
                onChangePage={props.handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                striped
                highlightOnHover
                selectAllRowsItem
                selectAllRowsItemTex="Todos"
            />
        </div>
    );
};

export default ReservationsTable;
