import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Row, Col, Button, Typography, Spin } from 'antd';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import ReservationsTable from './ReservationsTable';
import useReservationsApi from '../../controllers/reservations_controller';
import { sendMail, parserEmailData } from '../../controllers/email_controller';
import DetailsModal from './DetailsModal';
// import EditModal from './EditModal';
import Swal from 'sweetalert2';
import styles from './index.module.scss';
import { QueryDatesContext } from '../../App'; // Importar el contexto global de fechas
import { useAuth } from '../../context/AuthContext';

const { Title } = Typography;

const Index = () => {
    const { idToken } = useAuth();
    const { queryDates, setQueryDates } = useContext(QueryDatesContext);
    const [reservationData, setReservationData] = useState([]);
    const [detailsModalController, setDetailsModalController] = useState(false);
    const [setEditModalController] = useState(false);
    const [detailsModalData, setDetailsModalData] = useState({});
    const [setEditModalData] = useState({});
    // const [setModalLoaderController] = useState(false);
    // const [setRefreshController] = useState(false);
    const [filterDateRange, setFilterDateRange] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const { getAllReservationsByDateRange } = useReservationsApi();

    const fetchData = async (page = 1) => {
        setLoading(true);
        const offset = (page - 1) * perPage;
        getAllReservationsByDateRange({ ...queryDates, limit: perPage, offset })
            .then((res) => {
                const results = Array.isArray(res.data) ? res.data : res.data.results;
                setReservationData(results || []);
                const total = res.data.total || (results ? results.length : 0);
                setTotalRows(total);
                setLoading(false);
            }).catch((e) => {
                console.log(e);
            });
    }

    useEffect(() => {
        if(idToken) fetchData(currentPage);
    }, [idToken, currentPage, queryDates, perPage]);

    const handleDateRangeChange = (event, picker) => {
        let dateInit = picker.startDate.toDate();
        let dateEnd = picker.endDate.toDate();
        setQueryDates({
            date_init: moment(dateInit).format('YYYY-MM-DD HH:mm:ss'),
            date_end: moment(dateEnd).format('YYYY-MM-DD HH:mm:ss'),
            filter: filterDateRange,
        });
    }

    const handleFilterDateRange = (filter) => {
        setFilterDateRange(filter);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    // Nueva función para actualizar el número de registros por página y reiniciar la paginación
    const handleRowsPerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    }

    const handleSeeDetails = (data) => {
        setDetailsModalController(true);
        setDetailsModalData(data);
    }

    const handleEdit = (data) => {
        setEditModalController(true);
        setEditModalData(data);
    }

    const toggleDetailsModal = () => {
        setDetailsModalController(!detailsModalController);
        setDetailsModalData({});
    }

    // const toggleEditModal = () => {
    //     setEditModalController(!editModalController);
    //     setEditModalData({});
    // }

    // const handleEditFormSubmit = (e) => {
    //     e.preventDefault();
    //     setModalLoaderController(true);
    //     let dataToSend = {
    //         folio: editModalData.folio,
    //         trip_type: editModalData.trip_type,
    //         unit: editModalData.unit,
    //         pickup_location: editModalData.pickup_location,
    //         destination_location: editModalData.destination_location,
    //         total_passengers: parseInt(editModalData.total_passengers),
    //         fullname: editModalData.fullname,
    //         email: editModalData.email,
    //         cellphone: editModalData.cellphone,
    //         arrival_datetime: moment(editModalData.arrival_datetime).format('YYYY-MM-DD hh:mm:ss a'),
    //         arrival_airline: editModalData.arrival_airline,
    //         arrival_flight_number: editModalData.arrival_flight_number,
    //         departure_datetime: moment(editModalData.departure_datetime).format('YYYY-MM-DD hh:mm:ss a'),
    //         departure_airline: editModalData.trip_type === 'Round Trip' ? editModalData.departure_airline : '',
    //         departure_flight_number: editModalData.trip_type === 'Round Trip' ? editModalData.departure_flight_number : '',
    //         observations: editModalData.observations,
    //         payment_method: editModalData.payment_method,
    //         discount_code: editModalData.discount_code,
    //         discount_percent: editModalData.discount_percent,
    //         total_payment: editModalData.total_payment
    //     };
    //     updateReservation(editModalData.id_reservation, dataToSend)
    //         .then((res) => {
    //             console.log(res);
    //             setModalLoaderController(false);
    //             Swal.fire('Success!', 'The reservation has been updated.', 'success').then(() => {
    //                 toggleEditModal();
    //                 setRefreshController(true);
    //             })
    //         }).catch((e) => {
    //             console.log(e);
    //             setModalLoaderController(false);
    //             Swal.fire('Oops!', 'Something went wrong when updating the reservation...', 'error');
    //         })
    // }

    const resendEmail = (e) => {
        Swal.fire({
            title: 'Are you sure you want to resent confirmation email?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Accept'
        }).then((result) => {
            if (result.isConfirmed) {
                let arrival_date_time = moment(e.arrival_datetime).format('YYYY-MM-DD hh:mm:ss a');
                e['arrival_datetime'] = arrival_date_time;

                if (e.departure_datetime !== '') {
                    let departure_datetime = moment(e.departure_datetime).format('YYYY-MM-DD hh:mm:ss a');
                    e['departure_datetime'] = departure_datetime;
                }

                let email_data = parserEmailData(e);
                sendMail(email_data).then(_response => {
                    Swal.fire('', 'The email was resent', 'success');
                });
            }
        })
    }

    return (
        <>
            <div className={styles.container}>
                <Row justify="space-between" align="middle" gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Title level={1}>Reservaciones</Title>
                    </Col>
                    <Col xs={24} md={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexWrap: 'wrap', gap: '10px' }}>
                        <Button type={filterDateRange === 'arrival' ? 'primary' : 'default'} onClick={() => handleFilterDateRange('arrival')}>
                            Set Arrival Dates
                        </Button>
                        <Button type={filterDateRange === 'departure' ? 'primary' : 'default'} onClick={() => handleFilterDateRange('departure')}>
                            Set Departure Dates
                        </Button>
                        <div className="datepicker-range">
                            <small>
                                Set Date Reservation by {filterDateRange ? <b>{filterDateRange.toUpperCase()}</b> : 'Created Date'}:
                            </small>
                            <DateRangePicker
                                initialSettings={{ 
                                    startDate: moment(queryDates.date_init, 'YYYY-MM-DD').toDate(), 
                                    endDate: moment(queryDates.date_end, 'YYYY-MM-DD').toDate()
                                }}
                                onApply={handleDateRangeChange}>
                                <input className="input-date-range" type="text" readOnly />
                            </DateRangePicker>
                        </div>
                    </Col>
                    <Col span={24}>
                        <ReservationsTable
                            loading={loading}
                            records={reservationData}
                            handleSeeDetails={handleSeeDetails}
                            handleEdit={handleEdit}
                            resendEmail={resendEmail}
                            totalRows={totalRows}
                            perPage={perPage}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </Col>
                </Row>
            </div>
            <DetailsModal 
                modalController={detailsModalController} 
                toggleModal={toggleDetailsModal} 
                modalData={detailsModalData} 
            />
            {/* <EditModal ... /> */}
        </>
    )
}

export default Index;

