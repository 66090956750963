//// filepath: /f:/Sites/Transroute/bajatravel-admin/btt-admin/src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { firebase } from '../firebase/firebase-config';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user) {
        // Obtener el token apenas el usuario se autentica
        user
          .getIdToken(true)
          .then(token => {
            setIdToken(token);
            setLoading(false);
          })
          .catch(err => {
            console.error('Error al obtener el token:', err);
            setLoading(false);
          });
      } else {
        setIdToken(null);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, idToken, setIdToken }}>
      { !loading ? children : <div style={{ textAlign: 'center', marginTop: '50px' }}><span>Loading...</span></div> }
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);