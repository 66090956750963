import React from 'react';
import { Modal, Row, Col, Button } from 'antd';

export default function DetailsModal({ modalController, toggleModal, modalData }) {
    return (
        <Modal
            open={modalController}
            onCancel={toggleModal}
            title={`RESERVATION ID: ${modalData.booking_id}`}
            footer={[
                <Button key="close" type="primary" danger onClick={toggleModal}>
                    Close
                </Button>
            ]}
            width="80%"
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={16}>
                    <h3 style={{ textTransform: 'uppercase' }}>
                        {`TOTAL: $${modalData.total_fare} USD`}
                    </h3>
                    <p><b>Service:</b> {modalData.service}</p>
                    <p><b>Trip type:</b> {modalData.type_service}</p>
                    <p><b>Passengers:</b> {modalData.total_passengers}</p>
                    <p><b>Transport selected:</b> {modalData.service}</p>
                    <p><b>Pick-up location:</b> {modalData.arrival_from}</p>
                    <hr />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <h4>Contact/Traveler information:</h4>
                    <p><b>Full name:</b> {modalData.name} {modalData.lastname}</p>
                    <p><b>Phone:</b> {modalData.phone}</p>
                    <p><b>E-mail:</b> {modalData.email}</p>
                </Col>
                {modalData.comments && (
                    <Col xs={24} sm={12}>
                        <p><b>Comments:</b></p>
                        <p>{modalData.comments}</p>
                    </Col>
                )}
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <hr />
                    <h4>Pickup information: </h4>
                    <p><b>Arrival to:</b> {modalData.arrival_to}</p>
                    <p>
                        <b>Date/Time:</b> {modalData.arrival_date} - {modalData.arrival_time}
                    </p>
                    <p><b>Flight number:</b> {modalData.arrival_flight}</p>
                    <p><b>Airline Name:</b> {modalData.arrival_airline}</p>
                </Col>
                {modalData.type_service !== 'One Way' && (
                    <Col xs={24} sm={12}>
                        <hr />
                        <h4>Departure information: </h4>
                        <p><b>Departure to:</b> {modalData.departure_to}</p>
                        <p>
                            <b>Date/Time:</b> {modalData.departure_date} - {modalData.departure_time}
                        </p>
                        <p><b>Flight number:</b> {modalData.departure_flight}</p>
                        <p><b>Airline Name:</b> {modalData.departure_airline}</p>
                    </Col>
                )}
            </Row>
        </Modal>
    );
}

