import firebase from "firebase/app";
import 'firebase/firestore';
import'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCRErmnqY4XSGG7LlkM8g02xcRK11ObL3o",
    authDomain: "btt-admin-51577.firebaseapp.com",
    projectId: "btt-admin-51577",
    storageBucket: "btt-admin-51577.firebasestorage.app",
    messagingSenderId: "119086189627",
    appId: "1:119086189627:web:6cb921269e7b2ae440201e"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export {
    db,
    firebase
}